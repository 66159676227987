'use strict';

require('./src/assets/scss/init.scss');

exports.onClientEntry = () => {};

// require("prism-themes/themes/prism-cb.css");
require("prism-themes/themes/prism-darcula.css");
require("prismjs/plugins/line-numbers/prism-line-numbers.css");
require("prismjs/plugins/line-numbers/prism-line-numbers.css");
